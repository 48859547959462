import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If you're interested in contributing to Primer, you can find all the information you need here, including:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides/contribute/how-to-contribute"
        }}>{`How to contribute`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides/contribute/design"
        }}>{`Design contributions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides/contribute/content"
        }}>{`Writing UI content`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides/contribute/documentation"
        }}>{`Writing documentation`}</a></li>
    </ul>
    <p>{`For contribution guidelines for specific Primer implementations, please go to its respective repository:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/primer/react/blob/main/contributor-docs/CONTRIBUTING.md"
        }}>{`Primer React contribution guidelines`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://primer.style/view-components/contributing"
        }}>{`Primer ViewComponents contribution guidelines`}</a></li>
    </ul>
    <Note mdxType="Note">
  Please note that at this time, we are not looking for external contributions from non-GitHub staff. Some of the links
  included in these documents are only available to GitHub staff.
    </Note>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      